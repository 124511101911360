<script>
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDropdown from "@components/input/AkDropdown";
import AkDateTime from "@components/input/AkDateTime";
import AkCheckbox from "@components/input/AkCheckbox";
import AkDialog from "@components/general/AkDialog";
import Tag from 'primevue/tag';

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";
import dateFormatter from "@mixins/dateFormatter";
import validationStatusConst from "@mixins/const/validationStatusConst";
import linkTypeConst from "@mixins/const/linkTypeConst";
import linkNightConst from "@mixins/const/linkNightConst";

/* SERVICES */
import linkSheetService from "@services/linkSheetService";
import rolePermissionService from "@services/rolePermissionService";
import linkRowService from "@services/linkRowService";
import AkInputText from "@components/input/AkInputText.vue";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import AkCalendar from "@components/input/AkCalendar.vue";
import mobileMixin from "@mixins/mobileMixin";
import AkButton from "@components/input/AkButton.vue";
import interventionService from "@services/interventionService";
import userSettingsService from "@services/userSettingsService";

export default {
  components: {AkButton, AkCalendar, AkInputText, AkFormList, AkDropdown, AkDateTime, AkCheckbox, AkDialog, Tag},
  mixins: [randomRef, dateFormatter, validationStatusConst, roleMixin, linkTypeConst, linkNightConst, mobileMixin],
  metaInfo() {
    return {
      title: "link_sheet.list",
    }
  },
  data() {
    return {
      date: new Date(),
      perDay: false,
      showOnlyMine: false,
      author: "",
      night: null,
      sheetType: null,
      agerIdSelected: null,
      uerIdSelected: null,
      ceiIdSelected: null,
      list: [],
      typeList: [],
      current: {},
      currentYear: null,
      loading: false,
      toShow: 'all',
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      selectedRows: new Set(),
      selectAll: false,
      userParams: roleMixin.userParams,
      reloadListTimeout: null,
      ceiList: [],
      uerList: [],
      agerList: [],
      startDate: null,
      endDate: null,
      noFiche: "",
      showAlerts: 'null',
      showAlertsOptions: [
        {label: this.$t('yes'), value: 'true'},
        {label: this.$t('no'), value: 'false'},
        {label: this.$t('see_all'), value: 'null'}
      ],
      isExportDownloading: false,
      isCustomExportDownloading: false,
      userSettings: null,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();

    this.typeList.push({label: this.$t('data_show_type.all'), value: 'all'});
    this.typeList.push({label: this.$t('data_show_type.redacting'), value: 'redacting'});
    this.typeList.push({label: this.$t('data_show_type.archived'), value: 'archived'});
    this.typeList.push({label: this.$t('data_show_type.validated'), value: 'validated'});

    this.getStoredFilterValues();

    let linkSheetFilter = {};

    if (this.canOnlyViewArchivedLink()) {
      linkSheetFilter.showArchived = true;
    } else {
      switch (this.toShow) {
        case 'redacting':
          linkSheetFilter.showRedacting = true;
          break;
        case 'archived':
          linkSheetFilter.showArchived = true;
          break;
        case 'validated':
          linkSheetFilter.showValidated = true;
          break;
      }
    }

    linkSheetFilter.onlyMine = this.showOnlyMine;
    linkSheetFilter.perDay = this.perDay ? this.date : null;
    linkSheetFilter.author = this.author;
    linkSheetFilter.night = this.night;
    linkSheetFilter.sheetType = this.sheetType;
    linkSheetFilter.agerId = this.agerIdSelected;
    linkSheetFilter.uerId = this.uerIdSelected;
    linkSheetFilter.ceiId = this.ceiIdSelected;
    linkSheetFilter.startDate = this.startDate;
    linkSheetFilter.endDate = this.endDate;
    linkSheetFilter.noFiche = this.noFiche;
    linkSheetFilter.showAlerts = this.showAlerts;

    let p1 = linkSheetService.findAll(linkSheetFilter);
    p1.then(data => {
      this.list = data
      this.list.forEach(item => {
        item.agentFullName = item.creatorFirstName + ' ' + item.creatorLastName;
        item.nightLabel = this.displayForLinkNightConst(item.night);
        item.typeLabel = this.displayForLinkTypeConst(item.type);
        item.statusLabel = this.displayForValidationStatusConst(item.status);
      })
    });

    let p2 = linkSheetService.findCurrentYear();
    p2.then(data => this.currentYear = data);

    let p3 = rolePermissionService.authorizeAccessByPermissions([
      this.permissionConst.link.view,
      this.permissionConst.link.viewOther,
      this.permissionConst.link.viewValidated,
      this.permissionConst.link.viewValidatedOther,
      this.permissionConst.link.viewArchived,
      this.permissionConst.link.viewArchivedOther
    ], 'or');

    Promise.all([p1, p2, p3]).then(() => {
      this.selectedRows = new Set();
      this.reloadAgerUerCeiLists().then(() => {
        this.loading = false;
        this.getRef().hideLoader();
      });
    });
  },
  methods: {
    reloadList() {
     this.loading = true;
        this.getStoredFilterValues();
        let linkSheetFilter = this.getLinkSheetFilters();

        let p1 = linkSheetService.findAll(linkSheetFilter);
        p1.then(data => {
          this.list = data
          this.list.forEach(item => {
            item.agentFullName = item.creatorFirstName + ' ' + item.creatorLastName;
            item.nightLabel = this.displayForLinkNightConst(item.night);
            item.typeLabel = this.displayForLinkTypeConst(item.type);
            item.statusLabel = this.displayForValidationStatusConst(item.status);
          })
        });
        
        Promise.all([p1]).then(() => {
          this.selectedRows = new Set();
          this.reloadAgerUerCeiLists().then(() => {
            this.loading = false;
            this.getRef().hideLoader();
          });
        });
    },
    
    getLinkSheetFilters() {
      let linkSheetFilter = {};

      if (this.canOnlyViewArchivedLink()) {
        linkSheetFilter.showArchived = true;
      } else {
        switch (this.toShow) {
          case 'redacting':
            linkSheetFilter.showRedacting = true;
            break;
          case 'archived':
            linkSheetFilter.showArchived = true;
            break;
          case 'validated':
            linkSheetFilter.showValidated = true;
            break;
        }
      }
      linkSheetFilter.perDay = this.perDay ? this.date : null;
      linkSheetFilter.onlyMine = this.showOnlyMine;
      linkSheetFilter.author = this.author;
      linkSheetFilter.sheetType = this.sheetType;
      linkSheetFilter.agerId = this.agerIdSelected;
      linkSheetFilter.uerId = this.uerIdSelected;
      linkSheetFilter.ceiId = this.ceiIdSelected;
      linkSheetFilter.startDate = this.startDate;
      linkSheetFilter.endDate = this.endDate;
      linkSheetFilter.noFiche = this.noFiche;
      linkSheetFilter.showAlerts = this.showAlerts;
      linkSheetFilter.night = this.night;
      return linkSheetFilter;
    },
    
    reloadAgerUerCeiLists() {
      let p1 = agerService.findAllCurrent().then(data => this.agerList = data);
      let p2 = uerService.findAllCurrent().then(data => {
        if (this.agerIdSelected != null) this.uerList = data.filter(val => val.agerId === this.agerIdSelected);
        else this.uerList = data;
      });
      let p3 = ceiService.findAllCurrent().then(data => {
        if (this.agerIdSelected != null) data = data.filter(val => val.agerId === this.agerIdSelected);
        if (this.uerIdSelected != null) data = data.filter(val => val.uerId === this.uerIdSelected);
        this.ceiList = data;
      });
      return Promise.all([p1, p2, p3]);
    },
    delete() {
      this.getRef().resetMessages();
      linkSheetService.delete(this.current).then(data => {
        this.list = this.list.filter(val => val.id !== data.id);
        this.getRef().success(this.$t("link_sheet.deleted"))
        this.$refs.dialogDelete.hide();
      }).catch(e => {
        this.getRef().error(e.response.data.error);
        this.$refs.dialogDelete.hide();
      });
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
    verificationView() {
      if (this.canOnlyViewArchivedLink())
        this.reloadList();
      return true;
    },
    showAll() {
      this.showOnlyMine = false;
      this.userParams.link.showOnlyMine = this.showOnlyMine;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    showMine() {
      this.showOnlyMine = true;
      this.userParams.link.showOnlyMine = this.showOnlyMine;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    previousDay() {
      this.date = new Date(this.date.getTime() - (3600 * 1000 * 24));
    },
    nextDay() {
      this.date = new Date(this.date.getTime() + (3600 * 1000 * 24));
    },
    downloadSheet(data) {
      data.downloading = true;
      linkSheetService.download(data).finally(() => data.downloading = false);
    },
    onSelectAllChange() {
      this.selectedRows = new Set();
      if (this.selectAll) {
        this.list.forEach(item => {
          if (item.canDelete) {
            item.selected = true;
            this.selectedRows.add(item);
          }
        });
      } else {
        this.list.forEach(item => {
          item.selected = false;
        });
      }
    },
    onSelectedChange(data) {
      if (!data.selected) {
        if (this.selectAll) {
          this.selectAll = false;
        }
        this.selectedRows.delete(data);
      } else {
        this.selectedRows.add(data);
      }
    },
    deleteSelectedRows() {
      if (this.selectedRows.size === 0) {
        this.getRef().error(this.$t("link_sheet.no_link_sheet_selected"));
        return;
      }
      if (this.selectedRows.size > 50) {
        this.getRef().error(this.$t("link_sheet.too_many_link_sheets_selected"));
        return;
      }
      linkSheetService.deleteList(Array.from(this.selectedRows)).then(() => {
        if (this.selectedRows.size > 1)
          this.getRef().success(this.$t("link_sheet.link_sheets_deleted"));
        else
          this.getRef().success(this.$t("link_sheet.deleted"));
        this.reloadList();
        if (this.selectAll) {
          this.selectAll = false;
          this.selectedRows = new Set();
        }
      }).catch(() => {
        if (this.selectedRows.size > 1)
          this.getRef().error(this.$t("link_sheet.link_sheets_not_deleted"));
        else
          this.getRef().error(this.$t("link_sheet.deleted_failed"));
      });
    },
    resetFilter() {
      this.resetLinkUserParams();
      this.getStoredFilterValues();
      this.reloadList();
      this.getRef().success(this.$t("filter_reset_success"));
    },
    resetLinkUserParams() {
      this.$store.dispatch("resetLinkUserParams");
      this.userParams = this.$store.state.userParams;
    },
    getStoredFilterValues() {
      this.filters['global'].value = this.userParams.link.searchValue;
      this.toShow = this.userParams.link.typeToShow;
      this.perDay = this.userParams.link.perDay;
      this.date = new Date(this.userParams.link.date);
      this.author = this.userParams.link.author;
      this.night = this.userParams.link.night;
      this.sheetType = this.userParams.link.sheetType;
      this.agerIdSelected = this.userParams.link.agerIdSelected;
      this.uerIdSelected = this.userParams.link.uerIdSelected;
      this.ceiIdSelected = this.userParams.link.ceiIdSelected;
      this.showOnlyMine = this.userParams.link.showOnlyMine;
      this.startDate = this.userParams.link.startDate ? new Date(this.userParams.link.startDate) : null;
      this.endDate = this.userParams.link.endDate ? new Date(this.userParams.link.endDate) : null;
      this.noFiche = this.userParams.link.noFiche;
      this.showAlerts = this.userParams.link.showAlerts;
    },
    clearDateRange() {
      this.userParams.link.startDate = null;
      this.userParams.link.endDate = null;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    downloadExcel(custom) {
      if (custom) {
        this.isCustomExportDownloading = true;
      } else {
        this.isExportDownloading = true;
      }

      let linkSheetFilter = this.getLinkSheetFilters();
      linkSheetFilter.custom = custom;

      let p = linkSheetService.downloadExcel(linkSheetFilter);
      let promises = [p];
      p.catch(() => {
        this.isExportDownloading = false;
        this.isCustomExportDownloading = false;
        // this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).then(() => {
        this.isExportDownloading = false;
        this.isCustomExportDownloading = false;
      });
    },
    openSettingsDialog() {
      userSettingsService.getLinkSheetSettings(this.currentUser.userId).then(data => {
        this.userSettings = data;

        // Sort settings by ordre
        this.userSettings.settingsValue = this.sortDict(this.userSettings.settingsValue, "ordre");

        this.$refs.dialogSettings.show();
      });
    },
    sortDict(dict, field) {
      let keyValues = []

      for (let key in dict) {
        keyValues.push([key, dict[key]])
      }
      keyValues = keyValues.sort((a, b) => a[1][field] - b[1][field])
      let newDict = {}

      for (let key in keyValues) {
        newDict[keyValues[key][0]] = keyValues[key][1]
      }
      return newDict;
    },
    updateSettings() {
      this.$refs.dialogSettings.hide();
      userSettingsService.updateSettings(this.userSettings).then(() => {
        this.getRef().success(this.$t("settings_saved"));
      }).catch(() => {
        this.getRef().error(this.$t("settings_not_saved"));
      })
    },
    resetSettings(value) {
      for (let object in this.userSettings.settingsValue) {
        this.userSettings.settingsValue[object].value = value;
      }
    },
    isArchivedLink(data) {
      return data.status==='ARCHIVED';
    },
  },
  computed: {
   
    // CAN
   
    canDeleteSelectedRows() {
      let deletable = this.selectedRows.size > 0;
      for (let item of this.selectedRows) {
        deletable = deletable && item.canDelete;
      }

      return deletable;
    },
  },
  watch: {
    toShow() {
      if (this.loading) return;
      this.userParams.link.typeToShow = this.toShow;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    perDay() {
      if (this.loading) return;
      this.userParams.link.perDay = this.perDay;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    date() {
      if (this.loading) return;
      this.userParams.link.date = this.date;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    agerIdSelected() {
      if (this.loading) return;
      this.uerIdSelected = null;
      this.ceiIdSelected = null;
      this.userParams.link.agerIdSelected = this.agerIdSelected;
      this.userParams.link.uerIdSelected = this.uerIdSelected;
      this.userParams.link.ceiIdSelected = this.ceiIdSelected;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    uerIdSelected() {
      if (this.loading) return;
      this.ceiIdSelected = null;
      this.userParams.link.uerIdSelected = this.uerIdSelected;
      this.userParams.link.ceiIdSelected = this.ceiIdSelected;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    ceiIdSelected() {
      if (this.loading) return;
      this.userParams.link.ceiIdSelected = this.ceiIdSelected;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    night() {
      if (this.loading) return;
      this.userParams.link.night = this.night;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    sheetType() {
      if (this.loading) return;
      this.userParams.link.sheetType = this.sheetType;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    author() {
      if (this.loading) return;
      this.userParams.link.author = this.author;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    startDate() {
      if (this.loading) return;
      this.userParams.link.startDate = this.startDate;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    endDate() {
      if (this.loading) return;
      this.userParams.link.endDate = this.endDate;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    noFiche() {
      if (this.loading) return;
      this.userParams.link.noFiche = this.noFiche;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    showAlerts() {
      if (this.loading) return;
      this.userParams.link.showAlerts = this.showAlerts;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    filters: {
      handler() {
        this.userParams.link.searchValue = this.filters['global'].value;
        this.$store.dispatch("setUserParams", this.userParams);
      },
      deep: true
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormList :ref="ref" :title="$t('link_sheet.list')">
    <template v-slot:action>
      <div class="action-bar" v-if="!isMobile">
        <span class="p-input-icon-left">
          <i class="pi pi-search"/>
          <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
        </span>
        <button @click="openSettingsDialog()" class="btn btn-inverse-dark">
          <i class="fe fe-settings pr-1"/>
          {{ $t('intervention_list.settings_custom_excel') }}
        </button>
        <Button
            :disabled="isCustomExportDownloading"
            class="btn btn-inverse-primary"
            @click="downloadExcel(true)">
          <i class="fe fe-download pr-1" v-if="!isCustomExportDownloading"/>
          <i class="pi pi-spin pi-spinner mr-1" v-if="isCustomExportDownloading"></i>
          {{ $t('download_excel_custom') }}
        </Button>
        <Button
            :disabled="isExportDownloading"
            class="btn btn-inverse-primary"
            @click="downloadExcel()">
          <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
          <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
          {{ $t('download_excel') }}
        </Button>
        <RouterLink v-if=this.canCreateLink() :to="{ name: 'linkCreate' }" class="btn btn-inverse-primary">
          <i class="fe fe-plus pr-1"/>{{ $t('create') }}
        </RouterLink>
        <Button
            v-if="this.canDeleteLink()"
            v-text="$t('link_sheet.delete_selected_link_sheets')"
            class="btn btn-inverse-danger"
            @click="deleteSelectedRows()"/>
      </div>
    </template>

    <template v-slot:search>
      <div class="row" v-if="isMobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body action-bar flex-start">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
              </span>
              <button @click="openSettingsDialog()" class="btn btn-inverse-dark">
                <i class="fe fe-settings pr-1"/>
                {{ $t('intervention_list.settings_custom_excel') }}
              </button>
              <Button
                  :disabled="isCustomExportDownloading"
                  class="btn btn-inverse-primary"
                  @click="downloadExcel(true)">
                <i class="fe fe-download pr-1" v-if="!isCustomExportDownloading"/>
                <i class="pi pi-spin pi-spinner mr-1" v-if="isCustomExportDownloading"></i>
                {{ $t('download_excel_custom') }}
              </Button>
              <Button
                  :disabled="isExportDownloading"
                  class="btn btn-inverse-primary"
                  @click="downloadExcel(false)">
                <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
                <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
                {{ $t('download_excel') }}
              </Button>
              <RouterLink v-if=this.canCreateLink() :to="{ name: 'linkCreate' }" class="btn btn-inverse-primary">
                <i class="fe fe-plus pr-1"/>{{ $t('create') }}
              </RouterLink>
              <Button
                  v-if="this.canDeleteLink()"
                  v-text="$t('link_sheet.delete_selected_link_sheets')"
                  class="btn btn-inverse-danger"
                  @click="deleteSelectedRows()"/>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="action-card flex-end">
                <div>
                  <button v-if=showOnlyMine @click="showAll()" class="btn btn-inverse-primary">
                   {{ $t('see_all')  }}
                  </button>
                  <button v-else @click="showMine()" class="btn btn-inverse-primary">
                  {{ $t('see_mine') }}
                  </button>
                  <button @click="resetFilter()" class="btn btn-inverse-primary">
                  {{ $t('reset_filter') }}
                  </button>
                </div>
              </div>
              <div class="pt-3">
                <div class="filters">
                  <AkDropdown :label="$t('data_show_type.show_choice')"
                              v-if=!canOnlyViewArchivedLink()
                              v-model=toShow
                              :options=typeList
                              option-value="value"
                              option-label="label"
                              class-name=""/>
                  <AkCheckbox v-model="perDay"
                              :label="$t('data_show_type.per_day')"
                              class-name="filter-checkbox"/>
                  <AkDateTime v-model="date"
                              :showTime="false"
                              v-if="perDay"
                              :label="$t('date')"
                              class-name=""/>
                  <div class="form-group" v-if="perDay">
                    <label>&nbsp;</label>
                    <span style="display: flex;">
                      <span class="btn btn-xs btn-inverse-primary flex-center"
                            style="margin: 0 10px 0 5px; height: 40px;" @click="previousDay()">
                        <i class="fe fe-arrow-left"/>
                      </span>
                      <span class="btn btn-xs btn-inverse-primary flex-center" style="height: 40px;" @click="nextDay()">
                        <i class="fe fe-arrow-right"/>
                      </span>
                    </span>
                  </div>
                  <div class="date">
                    <AkCalendar :label="$t('date_range') + ' du'" :max-date="endDate" v-model="startDate"
                                selection-mode="single" class-name="" style="padding-left: 0 !important;"/>
                    <AkCalendar :label="'Au'" v-model="endDate" :min-date="startDate" selection-mode="single"
                                class-name="" style="padding-left: 0 !important;"/>
                    <i v-if="this.startDate || this.endDate" class="pi pi-times"
                       style="padding-top: 15px; cursor: pointer; color: #6c757d;" @click="clearDateRange()"/>
                  </div>
                  <AkDropdown :options="linkNightConst"
                              v-model="night"
                              :show-clear=true
                              :label="$t('day_night')"
                              class-name=""/>

                  <AkDropdown :options="showAlertsOptions"
                              v-model="showAlerts"
                              :show-clear=false
                              :label="$t('alerts')"
                              class-name=""/>
                </div>
                <div class="filters">
                  <AkInputText v-model="noFiche"
                               :label="$t('forecast_sheet.no_fiche')"
                               class-name=""/>
                  <AkInputText v-model="author"
                               :label="$t('author')"
                               class-name=""/>
                  <AkDropdown :options="linkTypeConst"
                              v-model="sheetType"
                              :show-clear=true
                              :label="$t('link_sheet.type')"
                              class-name=""/>
                  <AkDropdown :options="agerList"
                              v-model="agerIdSelected"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('ager_label')"
                              class-name=""/>
                  <AkDropdown :options="uerList"
                              v-model="uerIdSelected"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('uer_label')"
                              class-name=""/>
                  <AkDropdown :options="ceiList"
                              v-model="ceiIdSelected"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('cei_label')"
                              class-name=""/>
                </div>
              </div>
              <div class="table-responsive">
                <DataTable :always-show-paginator=false :paginator=true :rows=10
                           v-if="verificationView"
                           :loading="loading || !currentUser"
                           :rowsPerPageOptions="[10,20,50]"
                           :value="list"
                           class="table"
                           scrollable
                           scrollHeight="600px"
                           :globalFilterFields="['id', 'agentFullName', 'nightLabel', 'typeLabel', 'statusLabel', 'agerLabel','uerLabel','ceiLabel']"
                           v-model:filters="filters"
                           sortField="date"
                           :sortOrder=-1
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort
                           responsiveLayout="scroll"
                           stateStorage="session" stateKey="dt-link-list"
                           stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column bodyStyle="min-width: 200px; overflow: visible; text-align: right"
                          headerStyle="min-width: 200px; text-align: right">
                    <template #body="slotProps">
                      <AkButton v-if="slotProps.data.canDownload"
                                class-name="btn btn-xs btn-inverse-primary mr-1"
                                :loading="slotProps.data.downloading"
                                :on-click="() => downloadSheet(slotProps.data)">
                        <i class="fe fe-download"/>
                      </AkButton>
                      <RouterLink :to="{name: 'linkDetails', params: {id: slotProps.data.id}}"
                                  class="btn btn-xs btn-inverse-primary mr-1">
                        <i class="fe fe-eye"/>
                      </RouterLink>
                      <RouterLink v-if="slotProps.data.canUpdate"
                                  :to="{name: 'linkUpdate', params: {id: slotProps.data.id}}"
                                  class="btn btn-xs btn-inverse-primary mr-1">
                        <i class="fe fe-edit"/>
                      </RouterLink>
                      <span v-if=slotProps.data.canDelete class="btn btn-xs btn-inverse-danger"
                            @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/>
                      </span>
                    </template>
                  </Column>
                  <template v-if="canDeleteForecast()" >
                    <Column headerStyle="width: 3rem">
                      <template #header>
                        <AkCheckbox v-model="selectAll" @change="onSelectAllChange"/>
                      </template>
                      <template #body="slotProps">
                        <AkCheckbox v-if="slotProps.data.canDelete" v-model="slotProps.data.selected" @change="onSelectedChange(slotProps.data)"/>
                      </template>
                    </Column>
                  </template>
                  <Column field="id" :header="$t('link_sheet.strategie_traitement')" :sortable="true">
                    <template #body="slotProps">
                      <i v-if=slotProps.data.alerte class="fe fe-alert-triangle text-danger ml-sm-1"/>
                    </template>
                  </Column>
                  <Column field="id" :header="$t('link_sheet.no_fiche')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.id }}
                    </template>
                  </Column>
                  <Column field="creatorFullName" :header="$t('author')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.creatorFullName }}
                    </template>
                  </Column>
                  <Column field="year" :header="$t('year')" :sortable="true">
                    <template #body="slotProps">
                      <template v-if=!slotProps.data.year>{{ currentYear }} - {{ currentYear + 1 }}</template>
                      <template v-else>{{ slotProps.data.year }} - {{ slotProps.data.year + 1 }}</template>
                    </template>
                  </Column>
                  <Column field="night" :header="$t('day_night')" :sortable="true">
                    <template #body="slotProps">
                      {{ displayForLinkNightConst(slotProps.data.night) }}
                    </template>
                  </Column>
                  <Column field="date" :header="$t('date')" :sortable="true">
                    <template #body="slotProps">
                      <template v-if=slotProps.data.night>
                        {{ formatDate(slotProps.data.date) }} - {{ formatDate(this.addDays(slotProps.data.date, 1)) }}
                      </template>
                      <template v-else>
                        {{ formatDate(slotProps.data.date) }}
                      </template>
                    </template>
                  </Column>
                  <Column field="type" :header="$t('link_sheet.type')" :sortable="true">
                    <template #body="slotProps">
                      {{ displayForLinkTypeConst(slotProps.data.type) }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.ceiLabel }}
                    </template>
                  </Column>
                  <Column field="status" :header="$t('link_sheet.status')" :sortable="true">
                    <template #body="slotProps">
                      <Tag :value="displayForValidationStatusConst(slotProps.data.status)"
                           :severity="getColorForValidationStatusConst(slotProps.data.status)"/>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog
          ref="dialogDelete"
          :auto-hide-on-validate="true"
          :cancel-label="$t('no')"
          :validate-label="$t('yes')"
          :title="$t('link_sheet.delete_dialog')"
          width="450px"
          @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('link_sheet.confirm_delete') }}</span>
        </div>
      </AkDialog>

      <AkDialog ref="dialogSettings"
                :auto-hide-on-validate="true"
                :cancel-label="$t('cancel')"
                :title="$t('link_sheet.settings_dialog')"
                :validate-label="$t('validate')"
                width="650px"
                @validate="updateSettings()">
        <div class="confirmation-content d-flex flex-row justify-content-between" style="gap: 10px;">
          <div class="d-flex align-items-center">
            <i class="fe fe-info mr-1" style="font-size: 2rem"/>
            <div class="d-flex flex-column">
              <span>{{ $t('intervention.update_table_settings') }}</span>
              <span>Cocher les données que vous souhaitez voir</span>
            </div>
          </div>
          <div class="d-flex flex-column" style="gap: 10px;">
            <button :v-tooltip="$t('intervention_list.untick_all')"
                    class="btn btn-outline-primary btn-xs"
                    @click="resetSettings(false)">
              {{ $t('intervention_list.untick_all') }}
            </button>
            <button :v-tooltip="$t('intervention_list.tick_all')"
                    class="btn btn-outline-primary btn-xs"
                    @click="resetSettings(true)">
              {{ $t('intervention_list.tick_all') }}
            </button>
          </div>
        </div>
        <ak-checkbox
            v-for="setting in Object.keys(userSettings.settingsValue)"
            :key="setting"
            v-model="userSettings.settingsValue[setting].value"
            :label="$t('userSettings.linkSheet.' + setting)"
            class-name="col-12 checkbox mt-2"
            style="margin-bottom: 0 !important;"/>
      </AkDialog>
    </template>
  </AkFormList>
</template>